:root {
  --base-font: rgb(36, 34, 46);
  --primary: rgb(10, 145, 255);
  --secondary: rgba(10, 145, 255, 0.75);
}
html {
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Crimson Text', serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #111;
  background-color: #fcfcfc;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  margin: 150px auto;
  width: 445px;
}

.container {
  min-height: 50vh;
  padding-top: 50px;
}

.footer {
  display: flex;
  justify-content: center;
  font-size: 0.75rem;
  color: grey;
}

.navbar {
  margin-bottom: 50px;
}

.text-area {
  margin-top: 1rem;
}

a:-webkit-any-link {
  color: var(--base-font);
  text-decoration: none;
  font-size: 1rem;
  transition: 0.25s;
}

a:-webkit-any-link:hover {
  color: var(--primary);
  transition: 0.25s;
}

.anti-link {
  color: var(--primary) !important;
}

.anti-link:hover {
  color: var(--primary) !important;
  text-decoration: underline;
  transition: 0.4s;
}

.menu {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  height: 30px;
}

.sub-menu {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 50%;
  height: 30px;
}

.title {
  font-size: 1.35em !important;
  font-weight: 700;
}

@media (max-width: 600px) {
  .App {
    width: 90% !important;
  }
  .menu {
    display: block !important;
  }

  .title {
    margin-bottom: 10px !important;
  }

  .sub-menu {
    justify-content: space-between !important;
  }

  a:-webkit-any-link {
    padding-right: 10px;
  }
}
